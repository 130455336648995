<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>Rpaサーバー管理</el-breadcrumb-item>
      <el-breadcrumb-item>サーバー更新</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span><i class="el-icon-s-platform"></i>&nbsp;Rpaサーバー情報</span>
      </div>
      <el-form ref="form" :model="form" label-width="150px" :rules="rules">
        <el-form-item label="サーバーコード" prop="serverCode">
          <el-input v-model="form.serverCode" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="サーバー名" prop="serverName">
          <el-input v-model="form.serverName" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="コンメト">
          <el-input type="textarea" v-model="form.cmmt" maxlength="100" :rows="7" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="submitForm('form')">{{$t('updateUser.update')}}</el-button>
          <el-button round @click="resetForm()">{{$t('addGroup.back')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { rpaDetail,updateRpa } from "@/api/config";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "UpdateRpa",
  data() {
    return {
      backupInfo: {},
      memberList: [],
      form: {
        id: null,
        serverCode: "",
        serverName: "",
        cmmt: "",
        status: null,
      },
      rules: {
        serverCode: [
          { required: true, message: "サーバーコードを入力してください。", trigger: "blur" }
        ],
        serverName: [
          { required: true, message: "サーバー名を入力してください。", trigger: "blur" }
        ],
      }
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    refreshData() {
      rpaDetail({
        id: this.$route.query.id
      }).then(response => {
        this.form = { ...response.data };
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          updateRpa({
            id: this.form.id,
            serverCode: this.form.serverCode,
            serverName: this.form.serverName,
            cmmt: this.form.cmmt,
            status: this.form.status,
          }).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            this.$router.push(ROUTER_PATH.ADMIN_RPA);
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$router.push(ROUTER_PATH.ADMIN_RPA);
    }
  }
};
</script>
